var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.saving
        ? _c(
            "div",
            [
              _c("ct-centered-spinner", [
                _vm._v("\n      Creating SSL...\n    "),
              ]),
            ],
            1
          )
        : !_vm.domain
        ? _c("div", [
            _c("p", [_vm._v("\n      Domain is required to setup SSL.\n    ")]),
          ])
        : [
            _c("validation-observer", {
              ref: "observer",
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ handleSubmit }) {
                    return [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return handleSubmit(_vm.submitSSLForm)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-12" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "label-lg d-flex flex-row flex-nowrap align-items-center",
                                  },
                                  [
                                    _vm._v(
                                      "\n              Common Name:\n              "
                                    ),
                                    _c("fa-icon", {
                                      directives: [
                                        {
                                          name: "b-popover",
                                          rawName: "v-b-popover.hover.top",
                                          value:
                                            "Also known as the URL, the Common Name (CN) is the fully " +
                                            "qualified domain name used for DNS lookups of your server, and looks like " +
                                            '"www.example.com" or "example.com". Browsers use this information ' +
                                            "to identify your web site.",
                                          expression:
                                            "'Also known as the URL, the Common Name (CN) is the fully ' +\n                  'qualified domain name used for DNS lookups of your server, and looks like ' +\n                  '\"www.example.com\" or \"example.com\". Browsers use this information ' +\n                  'to identify your web site.'",
                                          modifiers: { hover: true, top: true },
                                        },
                                      ],
                                      staticClass:
                                        "question-circle pl-1 help-text",
                                      attrs: { icon: "question-circle" },
                                    }),
                                  ],
                                  1
                                ),
                                _c("validation-provider", {
                                  attrs: {
                                    name: "common_name",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c("b-form-input", {
                                              model: {
                                                value: _vm.formData.common_name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "common_name",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData.common_name",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "text-danger" },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(errors[0]) +
                                                    "\n              "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-6" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "label-lg d-flex flex-row flex-nowrap align-items-center",
                                  },
                                  [
                                    _vm._v(
                                      "\n              Organization:\n              "
                                    ),
                                    _c("fa-icon", {
                                      directives: [
                                        {
                                          name: "b-popover",
                                          rawName: "v-b-popover.hover.top",
                                          value:
                                            "This is the legal name under which your organization is " +
                                            "registered with the state.",
                                          expression:
                                            "'This is the legal name under which your organization is ' +\n                  'registered with the state.'",
                                          modifiers: { hover: true, top: true },
                                        },
                                      ],
                                      staticClass:
                                        "question-circle pl-1 help-text",
                                      attrs: { icon: "question-circle" },
                                    }),
                                  ],
                                  1
                                ),
                                _c("validation-provider", {
                                  attrs: {
                                    name: "organization",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c("b-form-input", {
                                              model: {
                                                value:
                                                  _vm.formData.organization,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "organization",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData.organization",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "text-danger" },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(errors[0]) +
                                                    "\n              "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-6" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "label-lg d-flex flex-row flex-nowrap align-items-center",
                                  },
                                  [
                                    _vm._v(
                                      "\n              City:\n            "
                                    ),
                                  ]
                                ),
                                _c("validation-provider", {
                                  attrs: { name: "city", rules: "required" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c("b-form-input", {
                                              model: {
                                                value: _vm.formData.city,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "city",
                                                    $$v
                                                  )
                                                },
                                                expression: "formData.city",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "text-danger" },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(errors[0]) +
                                                    "\n              "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-6" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "label-lg d-flex flex-row flex-nowrap align-items-center",
                                  },
                                  [
                                    _vm._v(
                                      "\n              State/Province:\n            "
                                    ),
                                  ]
                                ),
                                _c("validation-provider", {
                                  attrs: {
                                    name: "stateProvince",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c("b-form-input", {
                                              model: {
                                                value:
                                                  _vm.formData
                                                    .state_province_region,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "state_province_region",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData.state_province_region",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "text-danger" },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(errors[0]) +
                                                    "\n              "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-6" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "label-lg d-flex flex-row flex-nowrap align-items-center",
                                  },
                                  [
                                    _vm._v(
                                      "\n              Country:\n            "
                                    ),
                                  ]
                                ),
                                _c("validation-provider", {
                                  attrs: { name: "country", rules: "required" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c("b-form-select", {
                                              attrs: {
                                                placeholder: "Country",
                                                options: _vm.countries(),
                                              },
                                              model: {
                                                value: _vm.formData.country,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "country",
                                                    $$v
                                                  )
                                                },
                                                expression: "formData.country",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "text-danger" },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm.formSubmitted
                                                        ? errors[0]
                                                        : ""
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }