<template>
  <div>
    <div v-if="saving">
      <ct-centered-spinner>
        Creating SSL...
      </ct-centered-spinner>
    </div>

    <div v-else-if="!domain">
      <p>
        Domain is required to setup SSL.
      </p>
    </div>

    <template v-else>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submitSSLForm)">
          <!-- Common Name -->
          <div class="row">
            <div class="col-md-12">
              <label class="label-lg d-flex flex-row flex-nowrap align-items-center">
                Common Name:
                <fa-icon
                  v-b-popover.hover.top="'Also known as the URL, the Common Name (CN) is the fully ' +
                    'qualified domain name used for DNS lookups of your server, and looks like ' +
                    '&quot;www.example.com&quot; or &quot;example.com&quot;. Browsers use this information ' +
                    'to identify your web site.'"
                  class="question-circle pl-1 help-text"
                  icon="question-circle"
                />
              </label>
              <validation-provider v-slot="{ errors }" name="common_name" rules="required">
                <b-form-input
                  v-model="formData.common_name"
                />
                <span class="text-danger">
                  {{ errors[0] }}
                </span>
              </validation-provider>
            </div>
          </div>

          <!-- Organization -->
          <div class="row">
            <div class="col-md-6">
              <label class="label-lg d-flex flex-row flex-nowrap align-items-center">
                Organization:
                <fa-icon
                  v-b-popover.hover.top="'This is the legal name under which your organization is ' +
                    'registered with the state.'"
                  class="question-circle pl-1 help-text"
                  icon="question-circle"
                />
              </label>
              <validation-provider v-slot="{ errors }" name="organization" rules="required">
                <b-form-input
                  v-model="formData.organization"
                />
                <span class="text-danger">
                  {{ errors[0] }}
                </span>
              </validation-provider>
            </div>

            <!-- City -->
            <div class="col-md-6">
              <label class="label-lg d-flex flex-row flex-nowrap align-items-center">
                City:
              </label>
              <validation-provider v-slot="{ errors }" name="city" rules="required">
                <b-form-input
                  v-model="formData.city"
                />
                <span class="text-danger">
                  {{ errors[0] }}
                </span>
              </validation-provider>
            </div>
          </div>

          <!-- State/Province -->
          <div class="row">
            <div class="col-md-6">
              <label class="label-lg d-flex flex-row flex-nowrap align-items-center">
                State/Province:
              </label>
              <validation-provider v-slot="{ errors }" name="stateProvince" rules="required">
                <b-form-input
                  v-model="formData.state_province_region"
                />
                <span class="text-danger">
                  {{ errors[0] }}
                </span>
              </validation-provider>
            </div>

            <!-- Country -->
            <div class="col-md-6">
              <label class="label-lg d-flex flex-row flex-nowrap align-items-center">
                Country:
              </label>
              <validation-provider v-slot="{ errors }" name="country" rules="required">
                <b-form-select
                  v-model="formData.country"
                  placeholder="Country"
                  :options="countries()"
                />
                <span class="text-danger">
                  {{ formSubmitted ? errors[0] : '' }}
                </span>
              </validation-provider>
            </div>
          </div>
        </form>
      </validation-observer>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { countries } from '@/common/modules/countries'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import { makeToastMixin } from '@/mixins/makeToastMixin'

// Extend VeeValidate with the 'required' rule
extend('required', {
  ...required,
  message: 'This field is required',
})

export default {
  name: 'SslServiceForm',
  components: {
    CtCenteredSpinner:  () => import('@/components/shared/CtCenteredSpinner'),
  },
  mixins: [makeToastMixin],
  props: ['bus'],
  data () {
    return {
      formData: {
        common_name: '',
        organization: '',
        city: '',
        country: '',
        state_province_region: '',
      },
      unitedStates: [null, false, '', 'USA', 'United States'],
      formSubmitted: false,
      saving: false,
    }
  },
  computed: {
    ...mapGetters('companies', ['currentCompany']),
    ...mapGetters('domains', ['domain']),
  },
  mounted () {
    this.bus.$on('submitSslForm', this.submitSSLForm)
    this.preFillFormFromCompanyDetails()
  },
  methods: {
    ...mapActions('domains', [
      'createSSL',
    ]),
    ...mapActions('companies', [
      'loadActiveServiceByType',
    ]),
    ...mapActions('vouchers', [
      'fetchCompanyVouchers',
    ]),
    preFillFormFromCompanyDetails() {
      this.formData.organization = this.currentCompany.name
      this.formData.common_name = `www.${this.domain.domain_name.toLowerCase()}`
      if (this.currentCompany?.details &&
        'company_mailing_address' in this.currentCompany.details &&
        typeof this.currentCompany.details['company_mailing_address'] !== 'string'
      ) {
        const companyMailingAddress = this.currentCompany.details['company_mailing_address']

        for (const key in this.formData) {
          if (key in companyMailingAddress) {
            if (key === 'country' && this.unitedStates.includes(companyMailingAddress[key])) {
              this.formData[key] = 'US'
            } else {
              this.formData[key] = companyMailingAddress[key]
            }
          }
        }
      } else {
        this.formData['country'] = 'US'
      }
    },
    countries: () => countries,
    async submitSSLForm () {
      this.formSubmitted = true

      const valid = await this.$refs.observer.validate()

      if (!valid) {
        this.error('Please fill out the rest of the required information.')
      } else {
        this.saving = true
        const result = await this.createSSL({
          data: this.formData,
        })

        if (result?.success) {
          await this.fetchCompanyVouchers(this.currentCompany.id)
          await this.loadActiveServiceByType({
            id: this.currentCompany.id,
            type: 'domain-ssl',
          })
          this.bus.$emit('sslCreated')
          this.saving = false
        } else {
          this.saving = false
          this.error('Error Creating SSL')
        }
      }
    },
    error(errorMessage) {
      this.errorToast('Error', errorMessage)
      this.$emit('sslCreationFailed')
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
